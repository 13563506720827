<template>
  <setup-table
    :title="`Contractor`"
    :items="items"
    :headers="headers"
    :loading="loading"
    :clearItem="!showForm || !editedItem.id"
    @createItem="createItem()"
    @editItem="editItem"
    @deleteItems="deleteItems"
  >
    <template #itemForm v-if="showForm">
      <contractor-form
        ref="contractorForm"
        :value="editedItem"
        @submit="saveItem"
        @closeForm="closeForm"
      ></contractor-form>
    </template>
  </setup-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  GET_ADDITIONAL_TRANSPORTATIONS,
  SAVE_ADDITIONAL_TRANSPORTATION,
  DELETE_ADDITIONAL_TRANSPORTATIONS,
} from '@/store/modules/AdditionalTransportation/actions';
import SetupTable from './SetupTable.vue';
import ContractorForm from './ContractorForm.vue';

export default {
  name: 'AdditionalTransportationSetupTable',
  inject: ['eventHub'],
  components: { SetupTable, ContractorForm },
  data() {
    return {
      showForm: false,
      items: [],
      editedItem: {},
      loading: true,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone' },
        { text: 'Contact', value: 'contactName' },
      ],
    };
  },
  computed: {
    ...mapGetters('additionalTransportation', ['additionalTransportations']),
  },
  created() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('additionalTransportation', [
      GET_ADDITIONAL_TRANSPORTATIONS,
      SAVE_ADDITIONAL_TRANSPORTATION,
      DELETE_ADDITIONAL_TRANSPORTATIONS,
    ]),
    async fetchItems() {
      await this.getAdditionalTransportations();
      this.items = this.additionalTransportations.filter((e) => e.type == 'contractor');
      this.loading = false;
    },
    createItem() {
      this.editedItem = { id: 0, name: '' };
      this.showForm = true;
    },
    editItem(item) {
      this.editedItem = { ...item };
      this.showForm = true;
    },
    async saveItem(item) {
      try {
        const r = await this.saveAdditionalTransportation(item);
        if (r && r.id) {
          this.$myalert.success('Contractor saved');
          await this.fetchItems();
          this.editedItem = { ...item, id: r.id };
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async deleteItems(items) {
      const yes = await this.$myconfirm(
        `Are you sure you want to delete ${
          items.length > 1 ? 'these Contractors' : 'this Contractor'
        }? This operation cannot be undone.`
      );
      if (yes) {
        try {
          const r = await this.deleteAdditionalTransportations(items.map((e) => e.id));
          if (r.done) {
            this.$myalert.success(`${items.length > 1 ? 'Contractors' : 'Contractor'} deleted`);
            this.showForm = false;
            this.fetchItems();
          }
        } catch (error) {
          this.$myalert.error(error.message);
        }
      }
    },
    closeForm() {
      this.showForm = false;
    },
  },
};
</script>

<style lang="scss">
.table-action {
  margin-left: 10px;
}
</style>
